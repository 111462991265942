
import {Component, Vue} from 'vue-property-decorator';
import $ from 'jquery';
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'ozAfNU2w43Et5FZ3XA3G3VKNzmawMEL3',
});

@Component({
  name: 'index',
})
export default class extends Vue {
  private menuData = ['关于极创', '极创团队', '联系我们'];
  private status = '';
  created() {
    const status = localStorage.getItem('refreshToken');
    if (status) {
      this.status = '1';
    } else {
      this.status = '0';
    }
  }
  private to_login() {
    this.$router.push({
      path: '/login',
    });
  }
  private to_home() {
    this.$router.push({
      path: '/home',
    });
  }
  private tapbtn(index: number) {
    if (index == 0) {
      $('body,html').animate(
        {scrollTop: $('.aboutjs').offset().top - 100},
        500,
      );
    } else if (index == 1) {
      $('body,html').animate(
        {scrollTop: $('.teambox').offset().top - 100},
        500,
      );
    } else if (index == 2) {
      $('body,html').animate(
        {scrollTop: $('.contact').offset().top - 100},
        500,
      );
    }
  }
}
